<template lang="pug">
  div.wrap-module-set-profile.f.fh
    div(v-if="user").set-profile-content
      span.block.text-center.fz16.bold.mb4 まずはプロフィールを作成しましょう！
      span.block.text-center.fz16.bold.mb40 顔写真をアップロードしてください。
      div.f.fc.m16
        ItemProfileMediaUploader(
          ref="imageUploader"
          :existingImg="user.iconURL"
          :imgId="uid")
      div.wrap-input-name.f.fc
        v-text-field(label="名前" v-model="name")
</template>

<style lang="scss" scoped>
.wrap-module-set-profile {
  width: 100%;
  height: 100%;
  .set-profile-content {
    .wrap-input-name {
      width: 160px;
      margin: 0 auto;
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import ItemProfileMediaUploader from '@/components/item/ItemProfileMediaUploader'
import { database } from '../../../functions/shared/database'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    ItemProfileMediaUploader
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data () {
    return {
      user: null,
      name: '',
      iconURL: ''
    }
  },
  async created () {
    // this.userRef = db.collection('USER').doc(this.uid)
    // this.user = await this.userRef.get().then(d => {
    //   return d.data()
    // })
    this.user = await database.userCollection().findById(this.uid)
    this.name = this.user.name
    this.iconURL = this.user.iconURL
  },
  methods: {
    async save () {
      if (this.name === '') {
        window.alert('名前を入力してください。')
        return
      }
      // this.userRef.update({
      //   name: this.name,
      //   iconURL: this.$refs.imageUploader.getImgUrl()
      // })
      await database.userCollection().update(this.uid, {
        name: this.name,
        iconURL: this.$refs.imageUploader.getImgUrl()
      })
    }
  }
}
</script>
