<template lang="pug">
Auth(@loginFailed="onFailedAuthentication")
  div(v-if="uid").wrap-tutorial
    div(v-if="currentIndex == 1").tutorial-content
      ModuleTutorialSetProfile(:ref="tutorialContents[currentIndex]")
    div(v-if="currentIndex == 2").tutorial-content
      ModuleTutorialSetAddress(:ref="tutorialContents[currentIndex]")
    div.index-footer
      div.footer-content.f.fm.flex-around.py20
        div
          span(@click="onBack"
            :class="{'active': currentIndex != 1 }").fz14.cursor-pointer BACK
          //- v-icon(v-if="currentIndex != 1") keyboard_arrow_left
        div.index-circle.f.fm
          div(v-for="i in contentNum"
            :class="{'active': i == currentIndex }").circle.mr8
          //- div(
            v-for="i in contentNum"
            :class="{'active': i == currentIndex }"
            @click="onCircle(i)").circle.mr8
        div
          span(@click="onNext").active.fz14.cursor-pointer NEXT
          //- v-icon keyboard_arrow_right
</template>

<style lang="scss" scoped>
.wrap-tutorial {
  position: relative;
  width: 100%;
  min-height: 100vh;
  .tutorial-content {
    height: calc(100vh - 64px);
  }
  .index-footer {
    background: #fff;
    position: fixed;
    width: 100%;
    height: 64px;
    left: 0;
    bottom: 0;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.3);
    .footer-content {
      width: 93%;
      max-width: 820px;
      margin: 0 auto;
      span {
        color: #bbb;
        &.active {
          color: #2a2a2a;
        }
      }
      .index-circle {
        .circle {
          display: block;
          width: 6px;
          height: 6px;
          background: #bbb;
          &.active {
            background: #2a2a2a;
          }
        }
      }
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/shared/Auth'
import ModuleTutorialSetProfile from '@/components/module/ModuleTutorialSetProfile'
import ModuleTutorialSetAddress from '@/components/module/ModuleTutorialSetAddress'
import { database } from '../../functions/shared/database'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    ModuleTutorialSetProfile,
    ModuleTutorialSetAddress
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data () {
    return {
      headerContent: null,
      showModalPage: false,
      modalPageContentId: '',
      currentIndex: 1,
      contentNum: 2,
      tutorialContents: ['setProfile', 'setAddress']
      // tutorialContents: ['setProfile', 'setAddress', 'setBankInfo', 'letGeolocation']
    }
  },
  methods: {
    onFailedAuthentication () {
      this.$router.push('/sign-in')
    },
    async mounted () {
      await this.saveAccessLog()
    },
    async onBack () {
      if (this.currentIndex !== 1) {
        this.currentIndex--
        await this.saveAccessLog()
      }
    },
    async onNext () {
      const currentComponent = this.$refs[this.tutorialContents[this.currentIndex]]
      if (this.currentIndex !== this.contentNum) {
        if (currentComponent.save) {
          const letNext = await currentComponent.save()
          if (letNext === false) return
        }
        this.currentIndex++
        await this.saveAccessLog()
      } else {
        this.$router.push('/create-order')
      }
    },
    onCircle (i) {
      this.currentIndex = i
    },
    async saveAccessLog () {
      let component
      if (this.currentIndex === 0) component = 'setProfile'
      if (this.currentIndex === 1) component = 'setAddress'
      if (component) {
        await database.accessLogCollection(this.uid).add({
          uid: this.uid,
          page: '/tutorial',
          component: component,
          accessedAt: new Date()
        })
      }
    }
  }
}
</script>
