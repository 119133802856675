<template lang="pug">
  div.wrap-module-set-address-info.f.fh
    div.set-address-info-content
      span.block.text-center.fz16.bold.mb40 あなたの住所を入力してください。
      div.wrap-input-address.f.fc
        v-text-field(label="住所" v-model="address")
      v-btn.mt-3(@click="generateGeocode") 登録する
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-set-address-info {
  width: 100%;
  min-height: 100%;
  .set-bank-info-content {
    .wrap-info {
      width: 320px;
      .left-grid {
        width: 70%;
      }
      .right-grid {
        width: 25%;
      }
    }
  }
  .v-btn {
    margin-left: 80px;
    background-color: $body_main !important;
    color: #fff;
    font-weight: bold;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import { database } from '../../../functions/shared/database'
import { gmapApi } from 'vue2-google-maps'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {},
  computed: {
    ...mapStateAuth(['uid']),
    google: gmapApi
  },
  data () {
    return {
      address: '',
      lat: 0,
      lng: 0,
      createdAt: new Date(),
      updateAt: new Date(),
      isMain: true,
      geocoder: {},
      hasAddress: false,
      isGeocoded: false
    }
  },
  async created () {
    const addresses = await database.addressCollection(this.uid).all()
    if (!addresses.length) {
      return
    }
    const address = addresses[0]
    this.addressId = address.id
    this.address = address.address
    this.lat = address.lat
    this.lng = address.lng
    this.createdAt = address.createdAt
    this.updateAt = address.updateAt
    this.isMain = address.isMain
    this.isGeocoded = true
    this.hasAddress = true
  },
  async mounted () {
    await this.$gmapApiPromiseLazy().then(() => {
      this.geocoder = new google.maps.Geocoder()
    })
  },
  methods: {
    async generateGeocode () {
      await this.geocoder.geocode({
        address: this.address
      }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
        // 緯度を取得
          this.lat = results[0].geometry.location.lat()
          // 経度を取得
          this.lng = results[0].geometry.location.lng()
          this.isGeocoded = true
          this.save()
        } else {
          this.isGeocoded = false
          window.alert('住所が正しく登録できませんでした。もう一度お試しください')
        }
      })
    },
    async save () {
      if (this.address === '') {
        window.alert('住所を入力してください。')
        return false
      } else if (!this.isGeocoded) {
        window.alert('住所を登録してください。')
        return false
      }

      if (this.hasAddress) {
        await database.addressCollection(this.uid).update(this.addressId, {
          address: this.address,
          lat: this.lat,
          lng: this.lng,
          createdAt: this.createdAt,
          updateAt: new Date(),
          isMain: true
        })
      } else {
        await database.addressCollection(this.uid).set('0', {
          address: this.address,
          lat: this.lat,
          lng: this.lng,
          createdAt: this.createdAt,
          updateAt: new Date(),
          isMain: true
        })
      }
      window.alert('登録完了しました！')
      this.$router.push('/create-order')
    }
  }
}
</script>
